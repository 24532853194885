@use 'sass:map';
@use '@angular/material' as mat;
@import "variables";

@mixin theme-reset($theme) {
    $color-config: mat.get-color-config($theme);
    $primary: map.get($color-config, 'primary');
    $accent: map.get($color-config, 'accent');
    $warn: map.get($color-config, 'warn');
    $foreground: map.get($color-config, 'foreground');
    $background: map.get($color-config, 'background');


    //input outline color
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: mat.get-color-from-palette($primary) !important;
        opacity: 1 !important;
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($accent) !important;
    }


    .mat-form-field-outline-start {
        border-radius: 28px 0 0 28px !important;
        min-width: 28px !important;
    }

    .mat-form-field-outline-end {
        border-radius: 0 28px 28px 0 !important;
    }



    .mat-header-cell {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        justify-content: flex-start;
        background-color: mat.get-color-from-palette($accent) !important;
        color: #fff;
        // border-color: $accent;
    }

    .mat-cell {
        font-size: 10px;
        justify-content: flex-start;

        // flex: 1%;
        // min-width: 1%;
        // max-width: 3%;
    }

    .mat-paginator {
        // background-color: mat.get-color-from-palette($accent) !important;
        color: mat.get-color-from-palette($accent) !important;

    }

    .mat-progress-spinner {

        // background-color: mat.get-color-from-palette($primary) !important;
        // color: mat.get-color-from-palette($accent) !important;
    }

    // mat-label error style
    // .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    //     color: red !important;
    // }

    // // mat-input error outline color
    // .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    //     color: red !important;
    //     opacity: 0.8 !important;
    // }

    // // mat-input carent color
    // .mat-input-element {
    //     caret-color: red !important;
    // }

    // // mat-input error carent color
    // ::ng-deep .mat-form-field-invalid .mat-input-element,
    // .mat-warn .mat-input-element {
    //     caret-color: red !important;
    // }

    //mat-input focused color



    .bg-primary-2 {
        background: mat.get-color-from-palette($accent);
        color: #fff;
    }

    .bg-primary-3 {
        background: mat.get-color-from-palette($primary);
        color: #fff;
        margin-bottom: 2%;
    }

    .bg-primary {
        background: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
    }

    .bg-accent {
        background: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
    }

    .bg-warn {
        background: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
    }

    .secondary-text-color {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }

    .primary-color {
        color: mat.get-color-from-palette($primary) !important;
    }

    .accent-color {
        color: mat.get-color-from-palette($accent) !important;
    }

    .warn-color {
        color: mat.get-color-from-palette($warn) !important;
    }

    .header {
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, base);

        .info-content {
            .top-contact-form {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }

    .content-header h4 {
        color: mat.get-color-from-palette($primary, lighter);
    }

    .favorites-container:after {
        color: mat.get-color-from-palette($accent);
    }


    .horizontal-menu {
        background: mat.get-color-from-palette($background, card);
    }

    .horizontal-menu-item {
        .horizontal-sub-menu {
            background: mat.get-color-from-palette($background, card);
        }

        .mat-button {
            color: mat.get-color-from-palette($foreground, secondary-text);

            .horizontal-menu-title {
                color: mat.get-color-from-palette($foreground, text);
            }

            &.parent-item {
                .horizontal-menu-icon {
                    background: mat.get-color-from-palette($background, focused-button);
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }

                &.active-link {
                    background: mat.get-color-from-palette($background, focused-button);

                    .horizontal-menu-icon {
                        background: mat.get-color-from-palette($primary);
                        // color: #fff;
                        color: mat.get-color-from-palette($accent);
                    }
                }
            }
        }
    }

    .menu-item {
        .mat-button {
            .menu-icon {
                background: mat.get-color-from-palette($background, focused-button);
                color: mat.get-color-from-palette($foreground, secondary-text);
            }

            .menu-title {
                color: mat.get-color-from-palette($foreground, text);
            }

            &.active-link {
                background: mat.get-color-from-palette($background, focused-button);

                .menu-icon {
                    background: mat.get-color-from-palette($primary);
                    // color: #fff;
                    color: mat.get-color-from-palette($accent, lighter);
                }
            }
        }
    }

    .menu-expand-icon {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }

    .breadcrumb a {
        // color: mat.get-color-from-palette($primary, lighter);
        color: mat.get-color-from-palette($accent);
    }

    .mat-toolbar mat-icon {
        color: mat.get-color-from-palette($accent);
    }

    fieldset {
        border-color: mat.get-color-from-palette($primary);
    }



    // mat-header-cell {
    //     // font-size: large;
    //     color: mat.get-color-from-palette($primary);
    // }


    // mat-table {
    //     border-color: mat.get-color-from-palette($primary);
    // }

    .top-toolbar,
    .landing-toolbar {
        background: mat.get-color-from-palette($primary, darker);
        color: mat.get-color-from-palette($primary, lighter);
    }

    .menu-toolbar {
        background: mat.get-color-from-palette($primary, 900);
        color: mat.get-color-from-palette($primary, 100);
    }

    .ps__thumb-y {
        background-color: mat.get-color-from-palette($primary);
    }

    .testimonial-item,
    .pricing-card {
        border-top: 4px solid mat.get-color-from-palette($accent);
    }

    /* ngx-charts */
    .ngx-charts {
        text {
            fill: mat.get-color-from-palette($foreground, text);
        }
    }

    [class*=gradient-] .ngx-charts {
        text {
            fill: #fff;
        }
    }

    /* ngx-pagination */
    .annular-pagination .ngx-pagination {
        margin-bottom: 0;
        padding: 4px;

        .current {
            background: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        }

        a:hover,
        button:hover {
            background: rgba(mat.get-color-from-palette($primary), 0.2);
            color: mat.get-color-from-palette($foreground, base);
        }
    }

    /* angular-calendar */
    .cal-day-view .cal-hour-segment:hover,
    .cal-day-view .cal-drag-over .cal-hour-segment,
    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-day-headers .cal-drag-over,
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open {
        background-color: rgba(mat.get-color-from-palette($primary), 0.4);
    }

    .cal-month-view .cal-cell-row:hover {
        background-color: rgba(mat.get-color-from-palette($primary), 0.05);
    }

    .cal-week-view .cal-header.cal-today,
    .cal-month-view .cal-day-cell.cal-today {
        background-color: rgba(mat.get-color-from-palette($primary), 0.4);
    }

    .cal-day-view .cal-hour:nth-child(odd) {
        background-color: inherit;
    }

    .cal-event-action i {
        margin: 4px;
    }

    /* ngx-quill */
    .ql-snow .ql-picker,
    .ql-editor.ql-blank::before {
        color: mat.get-color-from-palette($foreground, text);
    }

    .ql-snow .ql-stroke {
        stroke: mat.get-color-from-palette($foreground, text);
    }

    .ql-snow .ql-fill {
        fill: mat.get-color-from-palette($foreground, text);
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        background: mat.get-color-from-palette($background, app-bar);
    }

    /* ngx-charts */
    .ngx-charts {
        text {
            fill: mat.get-color-from-palette($foreground, text);
        }
    }



    // // mat-icon-stepper selected color change 
    // ::ng-deep .mat-step-header .mat-step-icon-selected,
    // .mat-step-header .mat-step-icon-state-done,
    // .mat-step-header .mat-step-icon-state-edit {
    //     background-color: red !important;
    // }

    // //input outline color
    // ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    //     color: red !important;
    //     // opacity: 1!important;
    // }

    // //mat-input focused color
    // ::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    //     color: red !important;
    // }

    // // mat-input error outline color
    // ::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    //     color: red !important;
    //     opacity: 0.8 !important;
    // }

    // // mat-input carent color
    // ::ng-deep .mat-input-element {
    //     caret-color: red !important;
    // }

    // // mat-input error carent color
    // ::ng-deep .mat-form-field-invalid .mat-input-element,
    // .mat-warn .mat-input-element {
    //     caret-color: red !important;
    // }

    // // mat-label normal state style
    // ::ng-deep .mat-form-field-label {
    //     color: rgba(0, 0, 0, .6) !important;
    //     // color: $mainColor!important;
    // }

    // // mat-label focused style
    // ::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
    //     color: red !important;
    // }

    // // mat-label error style
    // ::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    //     color: red !important;
    // }
    // // mat-icon-stepper selected color change
    //    .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    //       background-color: $custom-main-color !important;
    //    }

    //    // input outline color
    //    .mat-form-field-appearance-outline .mat-form-field-outline {
    //       color: $custom-main-color !important;
    //    }

    //    // mat-input focused color
    //    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    //       color: $custom-main-color !important;
    //    }

    //    // mat-input error outline color
    //    .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    //       color: $custom-main-color !important;
    //       opacity: 0.8 !important;
    //    }

    //    // mat-input caret color
    //    .mat-input-element {
    //       caret-color: $custom-main-color !important;
    //    }

    //    // mat-input error caret color
    //    .mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    //       caret-color: $custom-main-color !important;
    //    }

    //    // mat-label normal state style
    //    .mat-form-field-label {
    //       color: $custom-label-color !important;
    //    }

    //    // mat-label focused style
    //    .mat-form-field.mat-focused .mat-form-field-label {
    //       color: $custom-main-color !important;
    //    }

    //    // mat-label error style
    //    .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    //       color: $custom-main-color !important;
    //    }
}